import VueRouter from "vue-router";
import routes from "./routes";
import authService from '../services/AuthService'
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to,from,next)=>{
  let isLoggedIn = authService.isLoggedIn()
  if(to.matched.some(record => record.meta.requireAuth) && !isLoggedIn){
    next({name:'login'})
    return;
  }
  if(isLoggedIn && !(to.matched.some(record => record.meta.requireAuth))){
    next({name:'/'})
    return;
  }
  next();
})

export default router;
