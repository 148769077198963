<template>
  <nav class="navbar navbar-expand-lg navbar-absolute"
       :class="{'bg-white': showMenu}">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
      </div>
      <button class="navbar-toggler" type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>
      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <img
              src="../../assets/images/logo.png"
              class="logo"
              alt="">
            <div class="header-search">
              <v-text-field
                class="header-search-text"
                outlined
                ref="search"
                v-model="keyword"
                placeholder="Search"
                clearable
                prepend-innder-icon="mdi-magnify"
                @click:clear="clearSearch"
                @keyup.enter="submitSearch"
                @keyup="onSearchKeyup"
                hide-details
                dense
              >   
              </v-text-field>
            </div>

          <ul class="navbar-nav"> 
            <base-dropdown tag="li"
                           title-tag="a"
                           class="nav-item"
                           menu-classes="dropdown-navbar">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="photo">
                  <img :src="userImage">
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none" @click="logout">
                  Log out
                </p>
              </a>
              <!--<li class="nav-link">
                <router-link
                  :to="{name:'profile'}"
                >
                  <a href="javascript:void(0)" class="nav-item dropdown-item">Profile</a>
                </router-link>
              </li> 
              <div class="dropdown-divider"></div>-->
              <li class="nav-link" @click="logout">
                <a href="javascript:void(0)" class="nav-item dropdown-item">Log out</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import BaseDropdown from '@/components/BaseDropdown';
  import AuthService from '../../services/AuthService';
  import usrImg from'../../assets/images/user.png';

  export default {
    components: {
    CollapseTransition,
    BaseDropdown,
    
},
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      userImage(){
        return (this.user && this.user.image) ? this.user.image : usrImg
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        user: {},
        keyword:''
      };
    },
    watch:{
      $route(to,from){
        if(!this.$route.query.keyword)
          this.keyword = ''
        else
          this.keyword = this.$route.query.keyword
      }
    },
    mounted(){
      this.getUser()
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      getUser(){
        this.user = AuthService.getUser();
      },
      logout(){
        AuthService.logout()
        this.$router.push({name:'login'})
      },
      clearSearch(){
        this.keyword="";
        let query = {...this.$route.query}
        if(query.keyword){
          delete query.keyword;
          this.$router.replace({query})
        }
      },
      submitSearch(){
        if(this.keyword){
          let query = {...this.$route.query}
          query.keyword = this.keyword
          this.$router.push({query})
        }
      },
      onSearchKeyup(){
        return this.keyword === "" ? this.clearSearch() : false
      }
    }
  };
</script>
<style scoped>
.top-title{
  font-size: 20px;
  color: #ffffff;
}
.logo{
  width: 120px;
}
.header-search{
    width: 40%;
    text-align: center;
    position: relative;
    margin: 0px auto;
}
.header-search-text{
  background-color: #ffffff;
}

</style>
