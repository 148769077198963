import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: DashboardLayout,
    redirect: "/users",
    meta:{ requireAuth : true},
    children: [
      {
        path: "users",
        name: "users",
        component:() =>import("@/pages/Users.vue")
      },
      {
        path: "states",
        name: "states",
        component:() => import("@/pages/States.vue")
      },
      {
        path: "regions",
        name: "regions",
        component:() => import("@/pages/Region.vue")
      },
      {
        path: "cities",
        name: "cities",
        component:() => import("@/pages/Cities.vue")
      },
      {
        path: "community",
        name: "community",
        component:() => import("@/pages/Community.vue")
      }
    ],
  },
  {
    path: "/login",
    name: 'login',
    component : () => import("@/pages/Auth/Login.vue")
  },
  {
    path: "/forgot-password",
    name: 'forgot',
    component : () => import("@/pages/Auth/ForgotPassword.vue")
  },
  {
    path: "/reset-password",
    name: 'reset',
    component : () => import("@/pages/Auth/VerifyPassword.vue")
  },
  { path: "*", component: NotFound },
];

export default routes;
