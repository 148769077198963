export default{
    encyrpedKey : 'CG-key-Imby-Webrock',
    isLoggedIn () {
        let data = this.getToken()
        if(data){
            return true
        }
        return false
    },
    setUser(data){
        let token = data.token;
        let user = data.description;
        let encreptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), this.encyrpedKey)
        let encreptedUserToken = CryptoJS.AES.encrypt(token, this.encyrpedKey)
        localStorage.setItem('imby_token',encreptedUserToken)
        localStorage.setItem('imby_user',encreptedUserData)
    },
    getUser(){
        let user = localStorage.getItem('imby_user')
        if(user){
            let data = CryptoJS.AES.decrypt(user, this.encyrpedKey)
            data = data.toString(CryptoJS.enc.Utf8)
            return JSON.parse(data);
        }
        return {}
    },
    getToken(){
        let token = localStorage.getItem('imby_token')
        if(token){
            let data = CryptoJS.AES.decrypt(token, this.encyrpedKey)
            data = data.toString(CryptoJS.enc.Utf8)
            return data;
        }
        return ''
    },
    logout(){
        localStorage.removeItem('imby_token');
        localStorage.removeItem('imby_user');
    }
}