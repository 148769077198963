
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

import BlackDashboard from "./plugins/blackDashboard";
import './registerServiceWorker'
import VueSimpleAlert from "vue-simple-alert";
import vuetify from './plugins/vuetify'

Vue.use(BlackDashboard);
Vue.use(VueSimpleAlert);
Vue.use(VueRouter);

/* eslint-disable no-new */
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
