<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/users" name="Users" icon="mdi-account-group"/>
        <sidebar-link to="/states" name="States" icon="mdi-home-silo-outline"/>
        <sidebar-link to="/regions" name="Regions" icon="mdi-map-marker-circle"/>
        <sidebar-link to="/counties" name="Counties" icon="mdi-city"/>
        <sidebar-link to="/community" name="communities" icon="mdi-account-multiple"/>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
