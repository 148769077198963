<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App'
};
</script>
<style>
.theme--dark.v-btn.v-btn--has-bg {
    background-color: #2e4150;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: #2e4250 !important;
}
.v-application .primary--text {
    color: #2e4250 !important;
    caret-color: #2e4250 !important;
}
.theme--light.v-btn.v-btn--has-bg {
    background-color: #2e4250;
    color: #fff 
}
</style>